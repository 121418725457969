import { Injectable } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import {
  DealerResponse,
  VehicleSearchResponse,
  WorkshopSystemEnum,
  PackageTagEnum,
  PackageResponse,
  SelectedPackagesRequest,
  ExternalTyreUrlResponse,
  CalendarDayTimeSlotResponse,
  CampaignResponse,
  CalendarTimeSlotResponse,
  ActiveWorkshopSystemResponse,
  InsuranceCompanyResponse,
  PackageTypeEnum,
  GetTechUpdateStatusResponse,
  VehicleWarningLightsResponse
} from "src/domain/client";
import { FormTypeEnum, PageFormIdEnum } from "../classes/enums";
import { IProduct } from "../interfaces/gtm-interfaces";
import { MappingService } from "./mapping.service";
import { PagingService } from "./paging.service";
import {
  IAboutVehicleForm,
  IChooseSpecificationsForm,
  IContactInformationForm,
  IExtraServicesForm,
  IFindDealerForm,
  ITimeSelectionForm,
  IVboBookingForm,
  IWindscreenWizardForm,
  IWorkshopSystemSelectionForm
} from "../interfaces/interfaces";
import { PackageProductMapPipe } from "../pipes/package-product-map.pipe";
import { IActiveWorkshopSystemResponseBusinessProposal } from "../classes/active-workshop-system-response-business-proposal";
import { DialogWillInvalidateFormComponent } from "../components/layout/dialog-will-invalidate-form/dialog-will-invalidate-form.component";

@Injectable({
  providedIn: "root"
})
export class FormService {
  // Responses
  public warningLightsResponse: VehicleWarningLightsResponse[] = null;
  public carInfoResponse: VehicleSearchResponse = null;
  public activeWorkshopSystemsResponse: (
    | ActiveWorkshopSystemResponse
    | IActiveWorkshopSystemResponseBusinessProposal
  )[] = null;
  public dealersResponse: DealerResponse[] = null;
  public basePackagesResponse: PackageResponse[] = null;
  public insuranceProvidersResponse: InsuranceCompanyResponse[] = null;
  public additionalPackagesResponse: PackageResponse[] = null;
  public availableTimesResponse: CalendarDayTimeSlotResponse[] = null;
  public selectedWorkshopTime: CalendarTimeSlotResponse = null;
  public extraServiceLoadedInStep4 = false;
  public techUpdateStatus: GetTechUpdateStatusResponse = null;
  public externalTyreUrlResponse: ExternalTyreUrlResponse = null;

  private _campaignResponse: CampaignResponse = null;
  public get campaignResponse() {
    return this._campaignResponse;
  }
  public set campaignResponse(response: CampaignResponse) {
    this._campaignResponse = response;
    if (!this._campaignResponse) {
      this._hasValidCampaignCode = false;
      return;
    }
    const today = moment().startOf("day");
    const start = moment(this.campaignResponse.startDate);
    const end = moment(this.campaignResponse.endDate);

    this._hasValidCampaignCode =
      (start.isSameOrBefore(today) && end.isSameOrAfter(today)) ||
      (start.isSame(end) && end.isSameOrAfter(today));
  }

  private _hasValidCampaignCode = false;
  public get hasValidCampaignCode() {
    return this._hasValidCampaignCode;
  }

  // State data
  public providerIds = [""];

  public rejectedIsOnlyTyre = false;
  public searchedByRegno = true;

  public selectedWorkshopDate: CalendarDayTimeSlotResponse = null;
  public previousFormValue: any = null;
  public isStateDialogOpen = false;
  public segment: number = null;
  public isBookingMadeSuccessful = false;

  private readonly emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  private readonly phoneRegex = /^[^a-zA-Z]{1,20}$/;
  public readonly regNrRegex = /(^[A-Za-z]{3})(\d{2})([A-Za-z]|\d{1})$/;
  public readonly chassiNrRegex = /^(([A-Za-z]|\d){17})$/;

  // Nestled form groups for each step, for step 4 & 5 fields added once step loads
  public form = new FormGroup<IVboBookingForm>({
    aboutVehicleForm: new FormGroup<IAboutVehicleForm>(
      {
        carText: new FormControl("", [
          Validators.required,
          Validators.pattern(this.regNrRegex)
        ]),
        milage: new FormControl<number>(null, [
          Validators.required,
          Validators.max(99999),
          Validators.min(1),
          Validators.required,
          Validators.pattern("^[0-9]*$")
        ])
      },
      this.secureStateOnChanges(this)
    ),
    workshopSystemSelectionForm: new FormGroup<IWorkshopSystemSelectionForm>(
      {
        Service: new FormControl(false),
        Troubleshooting: new FormControl(false),
        Wheel: new FormControl(false),
        Windscreen: new FormControl(false),
        TechUpdate: new FormControl(false),
        Other: new FormControl(false)
      },
      [
        this.requireCheckboxesToBeCheckedValidator(),
        this.secureStateOnChanges(this)
      ]
    ),
    findDealerForm: new FormGroup<IFindDealerForm>(
      {
        workshop: new FormControl<string>(null, Validators.required),
        sorting: new FormControl("time")
      },
      this.secureStateOnChanges(this)
    ),
    chooseSpecificationsForm: new FormGroup<IChooseSpecificationsForm>({
      invalidateByDefault: new FormControl<number>(null, Validators.required),
      other: new FormControl<string>(null)
    }),
    extraServicesForm: new FormGroup<IExtraServicesForm>({
      invalidateByDefault: new FormControl<number>(null, Validators.required)
    }),
    timeSelectionForm: new FormGroup<ITimeSelectionForm>(
      {
        date: new FormControl<Date>(null, Validators.required),
        time: new FormControl<string>(null),
        selectedContainer: new FormControl<string>(null, Validators.required)
      },
      this.secureStateOnChanges(this)
    ),
    contactInformationForm: new FormGroup<IContactInformationForm>(
      {
        firstName: new FormControl("", [
          Validators.required,
          Validators.minLength(2)
        ]),
        lastName: new FormControl("", [
          Validators.required,
          Validators.minLength(2)
        ]),
        phone: new FormControl("", [
          Validators.required,
          Validators.pattern(this.phoneRegex)
        ]),
        email: new FormControl("", [
          Validators.required,
          Validators.pattern(this.emailRegex)
        ]),
        verifyEmail: new FormControl("", [
          Validators.required,
          Validators.pattern(this.emailRegex)
        ]), // ADD MATCH TO EMAIL ABOVE
        discountCode: new FormControl(""),
        smsChecked: new FormControl(false),
        other: new FormControl(""),
        personalDataUsage: new FormControl(false, Validators.requiredTrue)
      },
      [
        this.secureStateOnChanges(this),
        this.requireMatch("email", "verifyEmail")
      ]
    )
  });
  public proposalExtraServicePackages: PackageResponse[] = [];

  // Form for windsceen wizard
  public windscreenWizardForm = new FormGroup<IWindscreenWizardForm>({
    issue: new FormControl<string>(null),
    damages: new FormControl<string>(null)
  });

  constructor(
    private mappingService: MappingService,
    private pagingService: PagingService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private packageProductMapPipe: PackageProductMapPipe
  ) {}

  updateForm(newFormGroup: FormGroup): void {
    const name = this.mappingService.stepNumberToFormName(
      this.pagingService.formStepLocation
    );
    if (!name) return;
    (this.form as FormGroup<any>).removeControl(name);
    (this.form as FormGroup<any>).addControl(name, newFormGroup);
  }

  // If user does back and makes changes to the form.
  invalidateForm(location: PageFormIdEnum = null): void {
    if (!location) location = this.pagingService.formStepLocation;
    if (!location) return;
    this.isBookingMadeSuccessful = false;
    if (location < PageFormIdEnum.timeSelection) {
      this.availableTimesResponse = null;
      this.selectedWorkshopDate = null;
      this.selectedWorkshopTime = null;
      this.timeSelectionForm.reset();
    }
    if (location < PageFormIdEnum.chooseSpecifications) {
      this.rejectedIsOnlyTyre = false;
      this.additionalPackagesResponse = null;
      this.extraServicesForm.markAsUntouched();
      this.extraServicesForm.addControl(
        "invalidateByDefault",
        new FormControl(null, Validators.required)
      );
      this.basePackagesResponse = null;
      this.chooseSpecificationsForm.markAsUntouched();
      this.chooseSpecificationsForm.reset();
      this.chooseSpecificationsForm.addControl(
        "invalidateByDefault",
        new FormControl<number>(null, Validators.required)
      );
      this.insuranceProvidersResponse = null;
      this.warningLightsResponse = null;
      this.segment = null;
      if (!this.selectedWorkshop?.whatsAppEnabled) {
        document.getElementById("soultech")?.remove();
      }
    }
    if (location < PageFormIdEnum.findDealer) {
      this.dealersResponse = null;
      this.externalTyreUrlResponse = null;
      this.findDealerForm.markAsUntouched();
      this.findDealerForm.patchValue({
        workshop: null,
        sorting: "time"
      });
    }
    if (location < PageFormIdEnum.workshopSystemSelection) {
      this.workshopSystemSelectionForm.markAsUntouched();
      this.workshopSystemSelectionForm.patchValue(
        {
          Service: false,
          Troubleshooting: false,
          Wheel: false,
          Windscreen: false,
          TechUpdate: false,
          Other: false
        },
        { emitEvent: false }
      );
      this.activeWorkshopSystemsResponse = null;
    }
  }

  get isCurrentFormInitialized(): boolean {
    let initialized = false;
    switch (this.pagingService.formStepLocation) {
      case PageFormIdEnum.aboutVehicle:
        initialized = !!this.carInfoResponse;
        break;
      case PageFormIdEnum.workshopSystemSelection:
        initialized = !!this.activeWorkshopSystemsResponse;
        break;
      case PageFormIdEnum.findDealer:
        initialized = !!this.dealersResponse;
        break;
      case PageFormIdEnum.chooseSpecifications:
        initialized = !!this.basePackagesResponse;
        break;
      case PageFormIdEnum.extraServices:
        initialized = !!this.additionalPackagesResponse;
        break;
      case PageFormIdEnum.timeSelection:
        initialized = !!this.availableTimesResponse;
        break;
      default:
        initialized = true;
    }
    return initialized;
  }
  get isCurrentFormValid(): boolean {
    const control =
      this.pagingService.formStepLocation === PageFormIdEnum.summary
        ? this.form
        : this.form.get(
            this.mappingService.stepNumberToFormName(
              this.pagingService.formStepLocation
            )
          );
    return control ? control.valid : false;
  }

  get aboutVehicleForm(): FormGroup<IAboutVehicleForm> {
    return this.form.controls.aboutVehicleForm;
  }

  get workshopSystemSelectionForm(): FormGroup<IWorkshopSystemSelectionForm> {
    return this.form.controls.workshopSystemSelectionForm;
  }

  get findDealerForm(): FormGroup<IFindDealerForm> {
    return this.form.controls.findDealerForm;
  }

  get chooseSpecificationsForm(): FormGroup<IChooseSpecificationsForm> {
    return this.form.controls.chooseSpecificationsForm;
  }

  get extraServicesForm(): FormGroup<IExtraServicesForm> {
    return this.form.controls.extraServicesForm;
  }
  get timeSelectionForm(): FormGroup<ITimeSelectionForm> {
    return this.form.controls.timeSelectionForm;
  }

  get contactInformationForm(): FormGroup<IContactInformationForm> {
    return this.form.controls.contactInformationForm;
  }

  get allSelectedPackages(): SelectedPackagesRequest[] {
    return (
      this.selectedBasePackages
        ?.concat(this.selectedAdditionalPackages ?? [])
        ?.concat(this.selectedTimePackages ?? []) ?? []
    );
  }

  get allSelectedProducts(): IProduct[] {
    return this.getBaseSelectionAsProducts()
      .concat(this.getAdditionalSelectionAsProducts())
      .concat(this.getSelectedTimeSelectionJobsAsProducts());
  }

  get serviceTypeSelection(): WorkshopSystemEnum[] {
    return Object.keys(this.workshopSystemSelectionForm.controls)
      .filter(
        (key: string) =>
          this.workshopSystemSelectionForm.controls[key].value === true
      )
      .map((v) => WorkshopSystemEnum[v]);
  }

  isMatchingServiceAgreement(): boolean {
    if (
      !this.selectedWorkshop ||
      !this.carInfoResponse.serviceAgreementResponse
    )
      return true;
    if (!this.carInfoResponse.serviceAgreementResponse.dealerAgreement)
      return true;
    return this.carInfoResponse.serviceAgreementResponse.validDealers.includes(
      this.selectedWorkshop.number
    );
  }

  // custom validators
  public requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return (formGroup: FormGroup) => {
      return Object.keys(formGroup.controls).filter(
        (key: string) => formGroup.controls[key].value === true
      ).length < minRequired
        ? {
            requireCheckboxesToBeChecked: true
          }
        : null;
    };
  }

  public requireMatch(valueKey: string, matchKey: string): ValidatorFn {
    return (formGroup: FormGroup): null | ValidationErrors => {
      const match = formGroup.get(matchKey);
      if (formGroup.get(valueKey).value !== match.value) {
        match.setErrors({ match: false });
        return null;
      }
    };
  }

  public secureStateOnChanges(formService: FormService): ValidatorFn {
    return (formGroup: FormGroup): any => {
      if (
        !!formService.isStateChanged &&
        !formGroup.pristine &&
        !!formGroup.touched
      ) {
        if (formService.isStateDialogOpen) return;
        formService.isStateDialogOpen = true;
        document.getElementById("vbo-header").scrollIntoView(true);
        const ref = formService.dialog.open(DialogWillInvalidateFormComponent, {
          data: {
            title: formService.translateService.instant(
              "are-you-sure"
            ) as string
          }
        });
        ref.afterClosed().subscribe({
          next: (result: any) => {
            formService.isStateDialogOpen = false;
            if (result) {
              formService.invalidateForm();
            } else {
              formGroup.markAsUntouched();
              formGroup.markAsPristine();
              formGroup.patchValue(formService.previousFormValue, {
                onlySelf: true,
                emitEvent: true
              });
              formGroup.markAsTouched({ onlySelf: true });
              formGroup.markAsPristine();
            }
          }
        });
      }
      return;
    };
  }

  get isStateChanged(): boolean {
    const name = this.mappingService.stepNumberToFormName(
      this.pagingService.formStepLocation
    );
    if (!name || !this.form) return false;
    switch (name) {
      case "aboutVehicleForm":
        return !!this.workshopSystemSelectionForm.valid;
      case "workshopSystemSelectionForm":
        return !!this.findDealerForm.valid || !!this.dealersResponse;
      case "findDealerForm":
        return (
          !!this.chooseSpecificationsForm.touched ||
          !!this.chooseSpecificationsForm.valid
        );
      case "chooseSpecificationsForm":
        return (
          !!this.timeSelectionForm.touched ||
          !!this.timeSelectionForm.valid ||
          !!this.availableTimesResponse
        );
      case "extraServicesForm":
        return (
          !!this.timeSelectionForm.touched ||
          !!this.timeSelectionForm.valid ||
          !!this.availableTimesResponse
        );
      default:
        return false;
    }
  }

  get selectedWorkshop(): DealerResponse {
    if (!this.dealersResponse) return null;
    if (!this.findDealerForm.controls.workshop) return null;
    return this.dealersResponse.find(
      (w) =>
        w.number === this.findDealerForm.controls.workshop?.value?.toString()
    );
  }

  get milageValue(): number {
    return this.aboutVehicleForm?.controls?.milage?.value;
  }

  // Get selected values from form then map to instances in response object for base packages (step 4)
  get selectedBasePackages(): SelectedPackagesRequest[] {
    let packages: SelectedPackagesRequest[] = [];
    Object.values(FormTypeEnum).forEach((formName: string) => {
      if (this.chooseSpecificationsForm.get(formName)) {
        packages = packages
          .concat(
            ...(this.basePackagesResponse?.map((j: PackageResponse) => {
              if (
                Object.keys(
                  (this.chooseSpecificationsForm.get(formName) as FormGroup)
                    .controls
                ).includes(j.id) &&
                j.id ===
                  this.chooseSpecificationsForm
                    .get(formName)
                    .get("selectedContainer").value
              ) {
                let packageList = Object.keys(
                  this.chooseSpecificationsForm.get(formName).get(j.id)
                    .value as unknown
                ).filter((key: string) => {
                  return !!this.chooseSpecificationsForm
                    .get(formName)
                    .get(j.id)
                    .get(key).value;
                });
                if (j.isSubCategory)
                  packageList = [
                    this.chooseSpecificationsForm
                      .get(formName)
                      .get(j.id)
                      .get("subcategoryContainer").value as string
                  ];
                return new SelectedPackagesRequest({
                  packageId: +j.id,
                  subPackageIds: packageList
                });
              }
            }) ?? [])
          )
          .filter((j: SelectedPackagesRequest) => !!j);
      }
    });
    return packages;
  }

  // Get selected values from form then map to instances in response object for additional packages (step 5)
  get selectedAdditionalPackages(): SelectedPackagesRequest[] {
    return (
      this.additionalPackagesResponse
        ?.map((j: PackageResponse) => {
          if (
            Object.keys(this.extraServicesForm.value).includes(j.id.toString())
          ) {
            let shouldInclude = !!this.extraServicesForm.get(j.id).value
              .selected;
            let packageList = Object.keys(
              this.extraServicesForm.get(j.id).value as unknown
            ).filter(
              (key: string) =>
                !!this.extraServicesForm.get(j.id).get(key).value &&
                key !== "selected"
            );
            if (j.isSubCategory) {
              const categorySelection = this.extraServicesForm
                .get(j.id)
                .get("subcategoryContainer").value;
              if (categorySelection) {
                shouldInclude = true;
                packageList = [categorySelection];
              }
            }

            if (shouldInclude) {
              const packageId = +j.id;
              if (packageId) {
                return new SelectedPackagesRequest({
                  packageId,
                  subPackageIds: packageList
                });
              } else {
                if (!j.id && !j.packageExternalId) {
                  return null;
                }
                let id = j.id;
                if (!id) id = j.packageExternalId;
                return new SelectedPackagesRequest({
                  packageExternalId: id,
                  subPackageIds: packageList
                });
              }
            }
          }
        })
        ?.filter((j: SelectedPackagesRequest) => !!j) ?? []
    );
  }

  get selectedTimePackages(): SelectedPackagesRequest[] {
    return (
      this.basePackagesResponse
        ?.filter((x) => !!x)
        .filter(
          (j: PackageResponse) =>
            this.timeSelectionForm.controls.selectedContainer?.value?.toString() ===
            j.id
        )
        ?.map((j: PackageResponse) => {
          let shouldInclude =
            this.timeSelectionForm.controls?.selectedContainer?.value?.toString() ===
            j.id;
          let packageList = Object.keys(
            this.timeSelectionForm.get(j.id).value
          ).filter(
            (key: string) => !!this.timeSelectionForm.get(j.id).get(key).value
          );
          if (j.isSubCategory) {
            const categorySelection = this.timeSelectionForm
              .get(j.id)
              .get("subcategoryContainer").value;
            if (categorySelection) {
              shouldInclude = true;
              packageList = [categorySelection];
            }
          }
          if (shouldInclude)
            return new SelectedPackagesRequest({
              packageId: +j.id,
              subPackageIds: packageList
            });
        })
        ?.filter((j: SelectedPackagesRequest) => !!j) ?? []
    );
  }

  get selectedLights(): VehicleWarningLightsResponse[] {
    if (!this.warningLightsResponse) return [];
    return this.warningLightsResponse
      .map((light: VehicleWarningLightsResponse) => {
        if (
          !(
            this.chooseSpecificationsForm
              .get("troubleshootingForm")
              .get("icons") as FormGroup
          ).get(light.warningLightId.toString()).value
        )
          return null;
        return light;
      })
      .filter((l) => !!l);
  }

  getAdditionalSelectionAsProducts(): IProduct[] {
    return this.getSelectionAsProducts(
      this.additionalPackagesResponse,
      this.selectedAdditionalPackages
    );
  }

  getSelectedTimeSelectionJobsAsProducts(): IProduct[] {
    return this.getSelectionAsProducts(
      this.basePackagesResponse,
      this.selectedTimePackages
    );
  }

  getBaseSelectionAsProducts(): IProduct[] {
    return this.getSelectionAsProducts(
      this.basePackagesResponse,
      this.selectedBasePackages
    );
  }

  private getSelectionAsProducts(
    packageList: PackageResponse[],
    selectedList: SelectedPackagesRequest[]
  ): IProduct[] {
    const productList: IProduct[] = [];
    if (!packageList?.length) return productList;
    selectedList.forEach((x) => {
      const match = packageList.find((z) => z.id === x?.packageId?.toString());
      if (match) {
        productList.push(
          this.packageProductMapPipe.transform(
            match,
            this.carInfoResponse?.brandName
          )
        );
      }
      x.subPackageIds?.forEach((sp) => {
        const spMatch = match.subPackages?.find((z) => z.id === sp);
        if (spMatch) {
          productList.push(
            this.packageProductMapPipe.transform(
              spMatch,
              this.carInfoResponse?.brandName
            )
          );
        }
      });
    });
    return productList;
  }

  resetWithValidServiceAgreementDealers(): void {
    this.providerIds =
      this.carInfoResponse.serviceAgreementResponse?.validDealers;
    this.invalidateForm(2);
    this.pagingService.navigateToStep(3);
  }

  // Selected dropff option
  get selectedDropoffOption(): PackageResponse {
    const formValue = this.timeSelectionForm.controls.selectedContainer?.value;
    if (!formValue) return new PackageResponse({});
    const option = this.basePackagesResponse.find(
      (j) => j.id === formValue.toString()
    );
    if (option) return option;
    return new PackageResponse({});
  }

  public validateMinOneContainer(formService: FormService): ValidatorFn {
    return (): Record<string, any> | null => {
      let valid = false;
      Object.values(FormTypeEnum).forEach((formName: string) => {
        if (formService.chooseSpecificationsForm.get(formName)) {
          if (
            !!formService.chooseSpecificationsForm
              .get(formName)
              .get("selectedContainer").value ||
            !!valid
          ) {
            formService.chooseSpecificationsForm.setErrors([]);
            valid = true;
            return null;
          }
        }
      });
      if (!valid) {
        return {
          oneSelectedContainer: false
        };
      }
    };
  }

  get insuranceProviderName(): string | null {
    try {
      const current =
        this.chooseSpecificationsForm.controls.windscreenForm.controls.insurance
          .value;
      return this.insuranceProvidersResponse.find(
        (p: InsuranceCompanyResponse) =>
          p.insuranceCompanyId.toString() === current
      ).name;
    } catch {
      return null;
    }
  }

  get carIsCupra(): boolean {
    return this.carInfoResponse?.specialBrandCode === "Q";
  }

  initSpecificFormFromPackages(
    initFormGrop: FormGroup<any>,
    packages: PackageResponse[],
    disableInputs = false
  ): void {
    const recommended = packages.find((j: PackageResponse) =>
      j.tags.includes(PackageTagEnum.Recommended)
    );
    packages.forEach((pkg) => {
      const tmpForm = new FormGroup({});
      if (!pkg.isSubCategory) {
        pkg.subPackages.forEach((sp) => {
          tmpForm.addControl(
            sp.id,
            new FormControl({
              value:
                sp.tags.includes(PackageTagEnum.Recommended) ||
                sp.tags.includes(PackageTagEnum.MainPackage),
              disabled:
                disableInputs ||
                (!pkg.tags.includes(PackageTagEnum.Recommended) &&
                  pkg.type === PackageTypeEnum.OriginalService)
            })
          );
        });
      } else {
        const value = pkg.subPackages.find((subPackage) =>
          subPackage.tags.includes(PackageTagEnum.Recommended)
        );

        tmpForm.addControl(
          "subcategoryContainer",
          new FormControl({
            value: value?.id || "",
            disabled: disableInputs
          })
        );
      }
      initFormGrop.addControl(pkg.id, tmpForm);
    });

    // Each form group requires a value
    initFormGrop.addControl(
      "selectedContainer",
      new FormControl(
        {
          value: recommended ? recommended.id : null,
          disabled: disableInputs
        },
        [Validators.required]
      )
    );
  }
}
